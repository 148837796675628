<template>
  <transition name="dialog">
    <div v-if="show" class="fixed inset-0 flex items-end z-10">
      <div
        class="absolute inset-0 bg-black opacity-30"
        @click="onClickBackground"
      />
      <div class="content relative m-auto overflow-hidden rounded-lg shadow-xl">
        <slot />
      </div>
    </div>
  </transition>
</template>
<script lang="ts">
export default defineComponent({
  props: {
    force: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      show: false,
    }
  },
  mounted() {
    this.show = true
    document.addEventListener('keydown', this.onKeydown)
    if (document.activeElement) {
      ;(document.activeElement as HTMLElement).blur()
    }
  },
  beforeMount() {
    document.removeEventListener('keydown', this.onKeydown)
  },
  methods: {
    onClickBackground() {
      if (!this.force) {
        this.show = false
      }

      this.$emit('close')
    },
    onKeydown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        this.$emit('close')
      }
    },
  },
})
</script>
<style lang="postcss" scoped>
.dialog-enter-active,
.dialog-leave-active {
  transition: opacity 250ms;

  .content {
    transition: all 250ms;
  }
}
.dialog-enter,
.dialog-leave-to {
  @apply opacity-0;
}
.dialog-enter {
  .content {
    @apply opacity-0;

    transform: translateY(12.5%);
  }
}
.dialog-leave-to {
  .content {
    @apply opacity-0;

    transform: translateY(12.5%);
  }
}
</style>
